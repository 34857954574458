<template>
  <div style="background: #f5f5f5;height: 100%;">
    <div class="home">

      <div class="mian">
        <div class="flx"><span>车牌号码:</span> <input type="text" @click="show =true" readonly v-model="form.carNo"></div>
        <div class="flx"><span>您的姓名:</span> <input type="text" v-model="form.ownerName"></div>
        <div class="flx"><span>联系方式:</span> <input type="tel" v-model="form.phone"></div>
        <div class="flx"><span>交强险日期:</span> <input type="text" @click="setTime(1)" readonly v-model="form.jqxExpire"></div>
        <div class="flx"><span>商业险日期:</span> <input type="text" @click="setTime(2)" readonly v-model="form.syxExpire"></div>

        <div class="flsx">
          <div style="flex: 1;">
            <div>身份证(选填):</div>
            <div class="imgdiv" v-if="form.idPic != null">
              <img :src="form.idPic" alt="">
              <img class="cha" @click="form.idPic = null" src="../../assets/img/cha.png" alt="">
            </div>
            <van-uploader v-else :max-count="1" :name="1" :after-read="onRead">
              <div class="scdiv">
                <van-icon class="jia" name="plus" size="40" />
              </div>
            </van-uploader>

          </div>
          <div style="flex: 1;">
            <div>行驶证(选填):</div>
            <div class="imgdiv" v-if="form.drivePic != null">
              <img :src="form.drivePic" alt="">
              <img class="cha" @click="form.drivePic = null" src="../../assets/img/cha.png" alt="">
            </div>
            <van-uploader :max-count="1" :name="2" v-else :after-read="onRead">
              <div class="scdiv">
                <van-icon class="jia" name="plus" size="40" />
              </div>
            </van-uploader>

          </div>
        </div>

      </div>
      <van-button class="bots" color="#5f2d3d" block @click="submit">确 认 提 交</van-button>
      <keyBoard v-model="value" v-show="show" @getjp="getjp" :show.sync="show"></keyBoard>
    </div>
    <van-calendar color="#5f2d3d" :min-date="minDate" :max-date="maxDate" v-model="showtime" @confirm="onConfirm" />
  </div>
</template>

<script>
import keyBoard from "@/components/vant-number-plate/vnp-keyboard";
import {
  bplist,
  syscar,
  wxLogin,
  uploadPicture,
  getcar,
} from "../../api/check";
export default {
  components: {
    keyBoard,
  },
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      show: false,
      showtime: false,
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2030, 0, 31),
      val: ["", "", "", "", "", "", "", ""],
      value: "",
      type: 1,
      gid: null,
      form: {
        carNo: "",
        ownerName: "",
        phone: "",
        jqxExpire: "",
        syxExpire: "",
        idPic: null,
        drivePic: null,
      },
    };
  },
  methods: {
    getjp(val) {
      console.log(val);
      var _this = this;
      if (val.length < 1) {
        this.$toast("请输入车牌");
        return;
      }
      var data = {
        gid: this.gid,
        carNo: val,
      };
      getcar(data).then((e) => {
        if (e.code == 200) {
          // this.onLoad();
          if (e.data) {
            this.form.phone = e.data.phone;
            this.form.ownerName = e.data.ownerName;
            this.form.jqxExpire = e.data.insuranceExpire
              ? e.data.insuranceExpire
              : "";
            this.form.businessExpire = e.data.businessExpire
              ? e.data.businessExpire
              : "";
          }
        }
      });
      _this.form.carNo = val;
    },
    //选择日期
    formatDate(date) {
      var n = date.getFullYear();
      var y = date.getMonth() + 1;
      var r = date.getDate();
      if (y < 10) {
        y = "0" + y;
      }
      if (r < 10) {
        r = "0" + r;
      }
      return `${date.getFullYear()}-${y}-${r}`;
    },
    onConfirm(date) {
      this.showtime = false;
      if (this.type == 1) {
        this.form.jqxExpire = this.formatDate(date);
      } else {
        this.form.syxExpire = this.formatDate(date);
      }

      console.log(this.date);
    },
    setTime(type) {
      this.type = type;
      this.showtime = true;
    },
    submit() {
      if (this.form.carNo == "") {
        this.$toast("请输入车牌");
        return;
      } else if (this.form.ownerName == "") {
        this.$toast("请输入您的姓名");
        return;
      } else if (this.form.phone == "") {
        this.$toast("请输入手机号码");
        return;
      } else if (!/^1[3456789]\d{9}$/.test(this.form.phone)) {
        this.$toast("手机号码格式错误");
        return;
      } else if (this.form.jqxExpire == "") {
        this.$toast("请输入交强险日期");
        return;
      } else if (this.form.syxExpire == "") {
        this.$toast("请输入商业险日期");
        return;
      }
      this.form.gid = this.gid;
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认提交？",
        })
        .then(() => {
          syscar(this.form).then((e) => {
            if (e.code == 200) {
              if (e.data) {
                this.$toast.success("提交成功！");
                localStorage.setItem("Insurance", JSON.stringify(this.form));
              } else {
                this.$toast("当前车牌已加入");
              }

              // this.onLoad();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },

    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      bplist({ gid: this.gid })
        .then((e) => {
          loading.clear();

          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    appSource() {
      var _this = this;
      var count_down = 0;
      var dataTime = new Date(+new Date() + 8 * 3600 * 1000)
        .toJSON()
        .substr(0, 19)
        .replace("T", " ");
      const u = navigator.userAgent,
        app = navigator.appVersion;
      //const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        count_down = this.datedifference(_this.time, dataTime, 1);

        console.log("ios");
      } else {
        count_down = this.datedifference(_this.time, dataTime, 2);
        console.log("andriod");
      }
      return count_down;
    },
    onRead(file, detail) {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "上传中...",
      });
      // alert(sessionStorage.getItem('h5token'))
      var _this = this;
      // console.log(file);
      console.log(detail);
      // console.log(file.file);
      let files = this.dataURLtoFile(file.content, file.file.name);
      let formdata1 = new FormData();
      formdata1.append("file", files);
      // alert(sessionStorage.getItem('h5token'))

      uploadPicture(formdata1).then((e) => {
        loading.clear();
        if (e.code == 200) {
          console.log(e);
          this.$toast.success("上传图片成功");
          if (detail.name == 1) {
            _this.form.idPic = e.data;
          } else {
            _this.form.drivePic = e.data;
          }
          this.$forceUpdate();
          console.log(_this.form);
        }
      });
    },
    dataURLtoFile(dataurl, filename) {
      // 将base64转换为file文件
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
  created() {
    function getSearchString(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      return obj[key];
    }
    var _this = this;
    var search = window.location.search;
    this.search = window.location.href;
    // this.gid = getSearchString("gid", search); //结果：18
    this.gid= getSearchString("gid", search)?getSearchString("gid", search):484
    if (JSON.parse(localStorage.getItem("Insurance"))) {
      var form = JSON.parse(localStorage.getItem("Insurance"));
      this.form = form;
      this.value = form.carNo
      console.log(this.form);
    }
    // if (!this.$route.query.code) {
    //   const loading = this.$toast.loading({
    //     mask: true,
    //     duration: 0,
    //     message: "加载中...",
    //   });
    //   // wxLogin({ gid: this.$route.query.state,code:this.$route.query.code })
    //   wxLogin({ gid: 484, code: "051x2K1w3KpUu03ObR0w3ZBTfa0x2K1R" })
    //     .then((e) => {
    //       loading.clear();

    //       this.list = e.data;
    //     })
    //     .catch(() => loading.clear());
    // }
    // this.onLoad();
  },
};
</script>

<style lang="less" scoped>
body {
  background: #f5f5f5;
}
.home {
  background: url(../../assets/img/bximg.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mian {
  font-size: 18px;
  margin: 200px 30px 0 50px;
  color: #5f2d3d;
  font-weight: bold;
  .flx {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .flsx {
    display: flex;
    align-items: flex-start;
  }
  .van-uploader {
    border: 1px solid #5f2d3d;
    width: 140px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    // flex: 1;
  }
  .imgdiv {
    width: 140px;
    height: 80px;
    position: relative;
    img {
      width: 140px;
      height: 80px;
    }
    .cha {
      position: absolute;
      top: 0;
      right: 0;
      width: 26px;
      z-index: 6;
      height: 26px;
    }
  }
  .scdiv {
    width: 140px;
    line-height: 80px;
  }
}
input {
  border: none;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: none;
  border: none;
  border-bottom: 1px solid #5f2d3d;
  width: 180px;
  padding: 0 12px;
  flex: 1;
}
</style>
<style>
.bots {
  width: 240px;
  margin: auto;
  margin-top: 50px;
  font-size: 18px;
  text-align: center;
}
</style>
